.wrapper {
  display: flex;
  flex-direction: column;
  gap: 7px;

  padding-top: 20px;
  width: 100%;
}

.arrow {
  color: #1ee0ac;
  cursor: pointer;
}
.header {
  display: flex;
  align-items: center;
  gap: 5px;
  padding-bottom: 8px;
}
.icon {
  width: 15px;
}
.label {
  color: #fff;
  font-size: 19px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;

  cursor: pointer;

  position: relative;
  display: flex;
  gap: 6px;
}
.dropDownContaner {
  background: #2a2a2a;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #39393c;
  border-radius: 5px;
  position: relative;
  padding: 12px 12px;
}
.item {
  color: #1ee0ac;
  font-size: 15px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  cursor: pointer;
}
.dropDownItems {
  position: absolute;
  top: 52px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 100%;
  padding: 5px 0px;
  background: #2a2a2a;
  cursor: pointer;
  z-index: 5;
  border: 1px solid #39393c;
  border-radius: 5px;
}
.dropDownItem {
  padding: 10px 12px;
  transition: 0.3s;
}
.dropDownItem:hover {
  background-color: #a1a1a1;
  color: #2a2a2a;
}
@media only screen and (max-width: 520px) {
  .mainWrapper {
    top: 50px;
  }
  .wrapper {
    padding: 18px 12px;
    gap: 18px;
  }
  .suggestionContainer {
    padding: 18px 12px;
  }
  .dropDownContaner {
    padding: 10px 12px;
  }
  .dropDownItems {
    top: 45px;
  }
  .item {
    font-size: 14px;
  }
}
