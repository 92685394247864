.sidebar {
  position: fixed;
  left: 0px;
  top: 75px;
  background: #2a2a2a;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 35px;

  max-width: 339px;
  width: 100%;
  z-index: 5;
  height: calc(100vh - 58px);
  overflow-y: auto;
  overflow-x: hidden;
}
.info {
  padding: 0 30px;
  background: #232323;
}
.navHeading {
  color: rgba(161, 161, 161, 0.8);
  font-size: 11px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: 2.2px;
  text-transform: uppercase;
  padding-bottom: 12px;
}
.balanceContainer {
  display: flex;
  flex-direction: column;
  gap: 3px;
  border-bottom: 1px solid #39393c;
  padding: 20px 0;
}
.balance {
  color: #1ee0ac;
  font-size: 24px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  padding-top: 4px;
}
.currency {
  color: #fafafa;
  font-size: 16px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  text-transform: uppercase;
}
.inUsd {
  color: rgba(250, 250, 250, 0.8);
  font-size: 15px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.profitDeprsitInfo {
  padding: 20px 0;
}
.spaceBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding-bottom: 3px;
}
.key {
  color: #a1a1a1;
  font-size: 12px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  display: flex;
  align-items: center;
  gap: 5px;
}
.profit {
  color: #1ee0ac;
  display: flex;
  align-items: center;
}
.up {
  font-size: 16px;
}
.value {
  color: #1ee0ac;
  text-align: right;
  font-size: 12px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 600;
  line-height: 190%;
}
.valueCurrency {
  color: #fafafa;
  text-transform: uppercase;
}
.buttonContainer {
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  gap: 12px;
  padding-top: 20px;
}
.button {
  text-align: center;
  font-size: 12px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.48px;
  text-transform: uppercase;
  padding: 10px 25px;
  outline: none;
  cursor: pointer;
  background: #232323;
  border-radius: 4px;
}
.deposit {
  border: 1px solid #1ee0ac;

  box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.23);
  color: #1ee0ac;
}
.withdraw {
  border: 1px solid #ff5151;

  color: #ff5151;
}
.navContainer {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 0 30px;
}

.navItems {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 12px;
}

.navItem {
  color: #a1a1a1;
  font-size: 14px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.15px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 15px;
}
.contactTab {
  padding: 30px 20px;
  padding-left: calc(30px + 12px);
  background: #232323;
  margin-top: auto;
}

.navActive {
  font-weight: 700;
  color: #2a2a2a;
  border-radius: 6px;
  background: #1ee0ac;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.15);
}
.icon {
  width: 18.33px;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */

.sidebar {
  scrollbar-width: thin;
  scrollbar-color: #1ee0ac rgba(250, 250, 250, 0.2);
}
/* For Firefox */
/* For Firefox */

/* Chrome, Edge, and Safari */
.sidebar::-webkit-scrollbar {
  width: 3px;
}

.sidebar::-webkit-scrollbar-track {
  background: rgba(250, 250, 250, 0.2);
}

.sidebar::-webkit-scrollbar-thumb {
  background: #1ee0ac;
  border-radius: 100%;
  border: none;
}
@media only screen and (max-width: 1199px) {
  .sidebar {
    transform: translateX(-150%);
    transition: 0.3s;
    left: 0;
  }
  .showSidebar {
    transform: translateX(0%);
    background: #151c33;

    transition: 0.3s;
  }
}
@media only screen and (max-width: 450px) {
  .sidebar {
    top: 110px;
  }
}
@media only screen and (max-width: 380px) {
  .sidebar {
    width: 100%;
  }
}
