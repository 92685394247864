.investmentInfo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  padding: 35px 0;
}

@media only screen and (max-width: 991px) {
  .investmentInfo {
    grid-template-columns: 1fr;
  }
}
