.wrapper {
  border-radius: 4px;
  border: 1px solid #39393c;
  background: #2a2a2a;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  max-width: 720px;
  width: 95%;
  margin: 0 auti;
  position: absolute;
  top: 100px;
  left: 50%;

  transform: translateX(-50%);
  z-index: 11;
  margin: 0 auto;
}
.close {
  cursor: pointer;
  color: #a1a1a1;
  font-size: 28px;
  margin-left: auto;
  margin-right: 18px;
  transform: translateY(18px);
}
.padding {
  padding: 25px 35px;
  border-bottom: 1px solid #39393b;
}
.id {
  color: #a1a1a1;
  font-size: 20px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: -0.48px;
  padding-bottom: 25px;
}
.idNumber {
  color: #1ee0ac;
}
.header {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
}
.imgAndBalance {
  display: flex;
  gap: 15px;
}
.img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}
.amount {
  color: #1ee0ac;
  font-size: 16px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: -0.2px;
  white-space: nowrap;
}

.text {
  color: #a1a1a1;
  font-size: 13px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  border: none;
}
.currency {
  color: #fafafa;
  text-transform: uppercase;
}
.secondaryText {
  text-transform: none;
  color: #fafafa;
}

.button {
  text-align: center;
  font-size: 12px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.26px;
  border-radius: 4px;
  padding: 8px 15px;
  border: none;
  outline: none;
  cursor: pointer;
  color: #2a2a2a;

  background: #1ee0ac;
  margin-left: auto;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.heading {
  color: #a1a1a1;
  font-size: 11px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: 2.2px;
  text-transform: uppercase;
  padding-bottom: 10px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  background: rgba(34, 34, 34, 0.6);
  backdrop-filter: blur(10px);
  z-index: 10;
  width: 100%;
  min-height: 100vh;
  cursor: pointer;
}
@media only screen and (max-width: 520px) {
  .wrapper {
    top: 50px;
  }
  .padding {
    padding: 18px 12px;
  }
  .grid {
    grid-template-columns: 1fr;
  }
}
