.wrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.textContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.title {
  color: #fafafa;
  font-size: 37px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: -1.2px;
}
.text {
  color: #a1a1a1;
  font-size: 13px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 23.1px;
}
.tabContainer {
  display: flex;
  align-items: center;
  gap: 24px;
  border-bottom: 1px solid #39393c;
  width: 100%;
  padding-bottom: 5px;
}
.tabTextWrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}
.tab {
  color: #d8d8d8;
  font-size: 13px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 23.1px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
}
.activeTab {
  color: #1ee0ac;
  font-size: 13px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 23.1px;
}

.activeTab::before {
  content: "";
  width: 100%;
  position: absolute;
  height: 2px;
  background: #1ee0ac;
  top: calc(100% + 4px);
}
.totalItem {
  color: #2a2a2a;
  text-align: center;
  font-size: 10.8px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 1502%;
  letter-spacing: 0.108px;
  border-radius: 3px;
  border: 1px solid #1ee0ac;
  background: #1ee0ac;
  height: 19px;
  width: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableContainer {
  border: 1px solid #39393c;
  background: #2a2a2a;

  border-radius: 22px;
  padding-bottom: 5px;
  overflow-x: auto;
}

.table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}
.row {
  border-bottom: 1px solid #39393c;
  background: #1b1b1b;
}
.row:last-child {
  border: none;
}
.valueContainer {
  background: #2a2a2a;
}
.heading,
.item {
  text-align: left;
  padding: 12px 15px;
  white-space: nowrap;
}
.heading {
  color: #fafafa;
  font-size: 11px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 2.2px;
  text-transform: uppercase;

  padding: 15px 15px;
}
.item {
  color: #1ee0ac;
  font-size: 12px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
}
.id,
.active {
  color: #1ee0ac;
}
.completed {
  color: #00ff00;
}
.pending {
  color: #ffda44;
}
.cancelled {
  color: #ff5151;
}
.currency {
  text-transform: uppercase;
}
.name {
  text-transform: uppercase;
}
.textAlignLeft {
  text-align: left;
}
.negativeAmount {
  color: #ff5151;
}
.positiveAmount {
  color: #1ee0ac;
}
.info {
  display: flex;
  align-items: center;
  gap: 10px;
}
.logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
@media only screen and (max-width: 520px) {
  .item {
    padding: 8px 15px;
  }
}

/* tr:nth-child(even) {
    background-color: #1a1a1a;
  } */

/* Firefox */
.tableContainer {
  scrollbar-width: thin;
  scrollbar-color: #1ee0ac rgba(250, 250, 250, 0.2);
  border-radius: 5px;
}

/* Chrome, Edge, and Safari */
.tableContainer::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

.tableContainer::-webkit-scrollbar-track {
  background: rgba(250, 250, 250, 0.2);
  border-radius: 5px;
}

.tableContainer::-webkit-scrollbar-thumb {
  background: #1ee0ac;
  border-radius: 100vh;
  border: none;
  border-radius: 5px;
}
