/* Customize the label (the container) */
.checkBoxContainer {
  display: flex;
  align-items: center;
}
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;

  background-color: transparent;
  border-radius: 4px;
  border: 2px solid #39393c;
  background: #2a2a2a;
  width: 24px;
  height: 24px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  cursor: pointer;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #1ee0ac;
  border: 2px solid transparent;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 7px;
  top: 3px;
  width: 4px;
  height: 10px;
  border: solid #2a2a2a;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.label {
  color: #a1a1a1;
  font-size: 14px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-top: 15px;
  margin-left: -2px;
}
@media only screen and (max-width: 399px) {
  .label {
    font-size: 11px;
    margin-top: 10px;
    margin-left: -8px;
  } /* Create a custom checkbox */
  .checkmark {
    width: 20px;
    height: 20px;
  }
  .container .checkmark:after {
    left: 5px;
    top: 1px;
  }
}
