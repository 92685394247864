.headerWrapper {
  background: #2a2a2a;
  padding: 12px 20px;
  height: 75px;
  display: flex;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}
.header {
  display: flex;
  justify-content: space-between;

  align-items: center;

  margin: 0 auto;
  width: 100%;

  gap: 10px;
}
.logoContainer {
  display: flex;
  align-items: center;
  gap: 5px;
  padding-right: 15px;
}
.logo {
  width: 97px;
}
.logoText {
  color: #fff;
  font-size: 23.053px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.userAndLanguageContainer {
  display: flex;
  align-items: center;
  gap: 15px;
}
.userContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 140px;
}
.userImagecontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #fff;
}
.userImage {
  width: 22px;
}
.nameContainer {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.name {
  color: #fafafa;
  font-size: 12px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.verified {
  color: #1ee0ac;
  font-size: 10px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 11px;
}
.languageContainer {
  position: relative;
}
.language {
  display: flex;
  align-items: center;
  gap: 10px;
}
.flag {
  width: 32px;
  height: 32px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}
.arrow {
  color: #fafafa;
  font-size: 20px;
  cursor: pointer;
}
.dropDownItems {
  position: absolute;
  z-index: 6;
  min-width: 100%;
  right: 0;
  top: 40px;

  display: flex;
  flex-direction: column;
  gap: 12px;
  border: 1px solid rgb(255, 255, 255, 0.4);
  border-radius: 8px;
  padding: 12px 15px;
  background: #2a2a2a;
}
.dropdownItem {
  display: flex;
  align-items: center;
  gap: 7px;
  cursor: pointer;
}
.countryFlag {
  width: 24px;
  height: 24px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}
.countryName {
  color: #fff;
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.contactTab {
  width: 100%;
}
.hamburger,
.close {
  color: #fafafa;
  font-size: 24px;
  display: none;
  cursor: pointer;
}
@media only screen and (max-width: 1199px) {
  .userAndLanguageContainer {
    gap: 22px;
  }
  .hamburger,
  .close {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .userContainer {
    min-width: auto;
  }
}
@media only screen and (max-width: 520px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }
  .logo {
    width: 28px;
  }
  .logoText {
    font-size: 18px;
  }

  .headerWrapper {
    height: auto;
    padding: 20px 20px;
  }

  .userAndLanguageContainer {
    margin-left: auto;
  }
  .userImagecontainer {
    width: 28px;
    height: 28px;
  }
  .userImage {
    width: 20px;
  }
  .nameContainer {
    gap: 2px;
  }
  .language {
    gap: 6px;
  }
  .flag {
    min-width: 28px;
    min-width: 28px;
    width: 28px;
    height: 28px;
  }
  .dropDownItems {
    padding: 9px 12px;
  }
  .countryFlag {
    width: 24px;
    height: 24px;
  }
  .countryName {
    font-size: 12px;
  }
}
